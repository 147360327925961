import Button from 'components/dist/atoms/Button';
import Drawer from 'components/dist/atoms/Drawer';
import DropdownMenu from 'components/dist/atoms/DropdownMenu';
import Icon from 'components/dist/atoms/Icon';
import Input from 'components/dist/atoms/Input';
import Skeleton from 'components/dist/atoms/Skeleton';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import { useRouter } from 'next/router';
import { memo, useCallback, useState } from 'react';
import { LoansKanbanFilter, LoanStageCategory, QUERY_PARAM_PHASE_CATEGORY } from 'src/constants/loan';
import { Route } from 'src/constants/ui';
import { Permission } from 'src/constants/user';
import { useKanbanLoans } from 'src/contexts/loans-kanban-context';
import { useConfirmUnsavedChanges } from 'src/hooks/use-confirm-unsaved-changes';
import { useLenderEmployees } from 'src/hooks/use-lender-employees';
import { getUserDisplayName } from 'src/utils/user/get-user-display-name';
import { useDebounceCallback, useMediaQuery } from 'usehooks-ts';

import { NextLinkComposed } from '../common/next-link-composed';
import { LoanRoleGuard } from '../user/loan-role-guard';
import { TeammatesSearchCheckbox } from './teammates-search-checkbox';

const loanOptions = [
    {
        icon: <Icon name="UserBag" strokeWidth={1.5} width={20} height={20} />,
        label: 'My Loans',
        filter: LoansKanbanFilter.LENDER_LOANS
    },
    {
        icon: <Icon name="Handbag" strokeWidth={1.5} width={20} height={20} />,
        label: 'All Loans',
        filter: LoansKanbanFilter.COMPANY_LOANS
    },
    {
        icon: <Icon name="Community" strokeWidth={1.5} width={20} height={20} />,
        label: 'Team Loans',
        filter: LoansKanbanFilter.TEAMMATES
    },
    {
        icon: <Icon name="Pin" strokeWidth={1.5} width={20} height={20} />,
        label: 'Pinned',
        filter: LoansKanbanFilter.PINNED
    },
    {
        icon: <Icon name="RemovePin" strokeWidth={1.5} width={20} height={20} />,
        label: 'Unpinned',
        filter: LoansKanbanFilter.UNPINNED
    },
];

const leadOptions = [
    {
        icon: <Icon name="UserBag" strokeWidth={1.5} width={20} height={20} />,
        label: 'My Leads',
        filter: LoansKanbanFilter.LENDER_LOANS
    },
    {
        icon: <Icon name="Handbag" strokeWidth={1.5} width={20} height={20} />,
        label: 'All Leads',
        filter: LoansKanbanFilter.COMPANY_LOANS
    },
    {
        icon: <Icon name="Community" strokeWidth={1.5} width={20} height={20} />,
        label: 'Team Leads',
        filter: LoansKanbanFilter.TEAMMATES
    },
    {
        icon: <Icon name="Pin" strokeWidth={1.5} width={20} height={20} />,
        label: 'Pinned',
        filter: LoansKanbanFilter.PINNED
    },
    {
        icon: <Icon name="RemovePin" strokeWidth={1.5} width={20} height={20} />,
        label: 'Unpinned',
        filter: LoansKanbanFilter.UNPINNED
    },
];

interface Props {
    lenderId: string;
    lead?: boolean;
}

export const LoansKanbanFilters = memo((props: Props) => {
    const { lenderId, lead } = props;
    const [inputValue, setInputValue] = useState('');
    const { filterState: { loansFilter, teamMembersIds }, onLoansFilterChange, state: { isLoading, filterQuery } } = useKanbanLoans();
    const options = [...(lead ? leadOptions : loanOptions)];
    const { employees } = useLenderEmployees({
        lenderId,
        skip: loansFilter !== 'TEAMMATES'
    });
    const teamMembersOptions = employees.map((user) => ({ id: user.id, label: getUserDisplayName(user) }));
    const selectedTeamMembersValue = teamMembersIds?.map(id => ({ id, label: teamMembersOptions.find((member) => member.id === id)?.label }))

    const isDesktop = useMediaQuery('(min-width:768px)');
    const router = useRouter();
    const handleFilterQueryChange = (query: string) => {
        onLoansFilterChange(loansFilter, query, teamMembersIds);
    }

    const handleTeammatesChange = useCallback((_event: React.ChangeEvent<{}>, value: { id: string, label: string }[]) => {
        onLoansFilterChange(LoansKanbanFilter.TEAMMATES, filterQuery, value.map((member) => member.id));
    }, [filterQuery, onLoansFilterChange]);

    const handleClearSearch = () => {
        setInputValue("");
        onLoansFilterChange(loansFilter, '', teamMembersIds);
    }

    const handleMenuItemClick = (
        index: number,
    ) => {
        const option = options[index];
        onLoansFilterChange(option.filter, filterQuery, []);
    };

    const debounceValueChange = useDebounceCallback(handleFilterQueryChange, 100)

    const onInputChange = (value: string) => {
        setInputValue(value);
        debounceValueChange(value);
    }
    useConfirmUnsavedChanges(false, handleClearSearch, null)
    const DropdownMenuDrawer = isDesktop ? DropdownMenu : Drawer;
    const DropdownMenuDrawerItem = isDesktop ? 'div' : Drawer.Close;
    const isLeadPhase = String(router.query.phase).toUpperCase() === LoanStageCategory.LEAD;

    const selectedOption = options.find((option) => option.filter === loansFilter);
    return (
        <Stack
            space="sm"
            row
            items='center'
            justify='between'>
            <LoanRoleGuard permission={Permission.CrudLoan}>
                <Button
                    size="sm"
                    asChild
                    className='gap-2 truncate justify-self-end sm:hidden'>
                    <NextLinkComposed
                        to={{
                            pathname: Route.CREATE_LOAN,
                            query: {
                                ...(isLeadPhase && {
                                    [QUERY_PARAM_PHASE_CATEGORY]: LoanStageCategory.LEAD,
                                }),
                            },
                        }}
                    >
                        <Icon name="Plus" width={18} height={18} /> Start {isLeadPhase ? 'Lead' : 'Loan'}
                    </NextLinkComposed>
                </Button>
            </LoanRoleGuard>
            {isLoading && <Skeleton className='w-44 h-8' />}
            {!isLoading && <DropdownMenuDrawer>
                <DropdownMenuDrawer.Trigger asChild>
                    <Button
                        size="sm"
                        variant='ghost'
                        spaceX="custom"
                        className='truncate bg-white font-normal flex items-center gap-1'>
                        <Icon name="FilterList" width={16} strokeWidth={1.5} height={16} />
                        {selectedOption?.label}
                        <Icon name="NavArrowDown" width={16} height={16} />
                    </Button>
                </DropdownMenuDrawer.Trigger>
                <DropdownMenuDrawer.Content
                    align="start"
                    side="bottom"
                    alignOffset={0}
                    className='w-full sm:w-72 max-w-full p-2'
                    id="filter-loans-menu">
                    <div
                        onSelect={(event) => event.preventDefault()}
                        className='p-0 focus:bg-white'>
                        <Input
                            autoFocus
                            value={inputValue}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => e.stopPropagation()}
                            wrapClassName='focus-within:border-blue-100 w-full bg-white'
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => e.stopPropagation()}
                            onInput={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e.target.value)}
                            placeholder="Search loan id, title, type or status" variant="sm"  >
                            <Input.Prefix>
                                <Icon name="Search" width={18} height={18} strokeWidth={1.5} />
                            </Input.Prefix>
                            {filterQuery && <Input.Suffix>
                                <Button
                                    data-hidden={!filterQuery}
                                    onClick={handleClearSearch}
                                    variant="ghost"
                                    size="sm"
                                    className='px-1'>
                                    <Icon name="Cancel" width={18} height={18} strokeWidth={1.5} />
                                </Button>
                            </Input.Suffix>}
                        </Input>
                    </div>
                    {options.map((option, index) => (
                        <DropdownMenuDrawerItem
                            tabIndex={0}
                            role="menuitem"
                            onKeyUp={() => handleMenuItemClick(index)}
                            onClick={() => handleMenuItemClick(index)}
                            className="gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2"
                            key={option.label}>
                            {option.icon}
                            <Text size="sm" >
                                {option.label}
                            </Text>
                        </DropdownMenuDrawerItem>
                    ))}
                </DropdownMenuDrawer.Content>
            </DropdownMenuDrawer>}
            {loansFilter === 'TEAMMATES' && (<TeammatesSearchCheckbox
                value={selectedTeamMembersValue}
                onChange={handleTeammatesChange}
                options={teamMembersOptions} />)}

        </Stack>
    );
});

LoansKanbanFilters.displayName = 'LoansKanbanFilters';