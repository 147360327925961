import Badge from 'components/dist/atoms/Badge';
import Button from 'components/dist/atoms/Button';
import HighlightText from 'components/dist/atoms/HighlightText';
import Icon from 'components/dist/atoms/Icon';
import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import Tooltip from 'components/dist/atoms/Tooltip';
import CopyButton from 'components/dist/molecules/CopyButton';
import { LoanReviewStatusBadge } from 'components/dist/molecules/LoanReviewStatusBadge';
import { JestTestId } from 'src/constants/tests';
import { Loan } from 'src/types/loan';
import { getLoanApplicant } from 'src/utils/loan/get-loan-applicant';
import { getReviewStatusChipLabel } from 'src/utils/loan/get-review-status-chip-label';
import { getReviewStatusTooltip } from 'src/utils/loan/get-review-status-tooltip';

import { UnreadCompanyLoanMessagesCount } from '../chat/unread-company-loan-messages-count.component';
import { LoanActionsMenu } from '../loan-actions-menu/loan-actions-menu';
import { LoanProgressBar } from '../loan-progress-bar';
import { RolesAvatars } from '../roles-avatars';

interface LoanCardProps {
    loan: Loan;
    highlightText?: string;
    onCopy?: (loanId: string) => void;
    onPin?: (loanId: string) => void;
    onSendMessageClick: (loanId: string) => void;
}

export const LoanCard = (props: LoanCardProps) => {
    const applicant = getLoanApplicant(props.loan.loanEntities);

    return <Tooltip.Provider>
        <Stack
            space="sm"
            className='group bg-white focus:border-blue-60 hover:border-blue-60 transition-colors will-change-auto focus:bg-blue-10 hover:bg-blue-10 rounded-md w-full h-full border-gray-neutral-80 border p-3'
            id={`loan-card-${props.loan.projectName.replace(/\s+/g, '-')}` /* for selenium automation */}>
            <Stack row space="sm">
                <Stack className='flex-1 gap-3 overflow-hidden shrink-0' >
                    <div>
                        <Text as="div" variant="secondary" size="xs"
                            className="group/code h-5 gap-2 inline-flex rounded px-1 items-center border border-gray-neutral-80 bg-white">
                            <Text
                                size="inherit"
                                variant="inherit"
                                className="inline-flex"
                            >
                                <HighlightText text={props.loan.shortCode?.toUpperCase()} highlightText={String(props.highlightText)} />
                            </Text>
                            <CopyButton
                                size="custom"
                                className='hidden group-hover/code:block '
                                text={props.loan.shortCode?.toUpperCase()} />
                        </Text>
                    </div>
                    <Stack space="sm" className='overflow-hidden'>
                        <Text
                            size="base"
                            weight="medium"
                            truncate
                            as="div">
                            <HighlightText text={props.loan.projectName} highlightText={String(props.highlightText)} />
                        </Text>
                        <Text variant="secondary" size="sm" className="break-all" truncate>
                            {applicant?.sherpaEntity?.name ?? "TBD"}
                        </Text>
                    </Stack>
                </Stack>
                <Tooltip>
                    <Tooltip.Trigger asChild>
                        <Button
                            aria-label="Pin to top"
                            className='hidden group-hover:block w-7 h-7 aspect-square p-0 text-gray-neutral-100 rounded-full'
                            size='sm'
                            variant="ghost"
                            onClick={(event) => {
                                props.onPin?.(props.loan.id)
                                event.preventDefault();
                            }}
                        >
                            <Icon name={props.loan.pinned ? "RemovePin" : "Pin"} width={20} height={20} strokeWidth={1.5} />
                        </Button>
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                        {props.loan.pinned ? 'Unpin' : 'Pin'}
                    </Tooltip.Content>
                </Tooltip>
                <LoanActionsMenu
                    onSendMessageClick={props.onSendMessageClick}
                    formElements={[]}
                    onCopy={props.onCopy}
                    loan={props.loan} />
            </Stack>
            <Stack className='flex-1'>
                <Stack row space="sm" className='items-center mt-2 flex-wrap'>
                    <Badge variant="secondary" className="inline-flex gap-2 truncate">
                        <Icon name="Bag" width={15} height={15} />
                        <div>
                            <HighlightText text={props.loan.templateProjectName} highlightText={String(props.highlightText)} />
                        </div>
                    </Badge>
                    <Tooltip>
                        <Tooltip.Trigger>
                            <LoanReviewStatusBadge
                                status={props.loan.reviewStatus}>
                                <HighlightText text={getReviewStatusChipLabel(props.loan.reviewStatus)} highlightText={String(props.highlightText)} />
                            </LoanReviewStatusBadge>
                        </Tooltip.Trigger>
                        <Tooltip.Content
                            align="start"
                            side="bottom"
                            alignOffset={0}>
                            {getReviewStatusTooltip(props.loan.reviewStatus)}
                        </Tooltip.Content>
                    </Tooltip>
                </Stack>
            </Stack>
            <Stack space="sm">
                <Separator />
                <Stack row justify='between'>
                    <RolesAvatars roles={props.loan.loanRoles} />
                    <UnreadCompanyLoanMessagesCount loanId={props.loan.id} companyId={props.loan.lender.id} />
                </Stack>
                <div data-testid={JestTestId.LoanProgressBar}>
                    <LoanProgressBar loan={props.loan} />
                </div>
            </Stack>
        </Stack>
    </Tooltip.Provider>
}




LoanCard.displayName = 'LoanCard';